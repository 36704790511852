import { ethers } from "ethers";
import { sample } from "lodash";
import { NetworkMetadata } from "lib/wallets";
import { isDevelopment } from "./env";

const { parseEther } = ethers.utils;

export const AVALANCHE = 43114;
export const ARBITRUM = 42161;
export const FANTOM_TESTNET = 4002;
export const FANTOM = 250;
export const BASE = 8453;
export const BASE_TESTNET = 84531;

export const DEFAULT_CHAIN_ID = BASE_TESTNET;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [BASE, BASE_TESTNET];

// if (isDevelopment()) {
//   SUPPORTED_CHAIN_IDS.push();
// }

export const IS_NETWORK_DISABLED = {
  [ARBITRUM]: true,
  [AVALANCHE]: true,
  [FANTOM_TESTNET]: true,
  [FANTOM]: true,
  [BASE]: false,
  [BASE_TESTNET]: false,
};

export function useNetworkIsDisabled(network: String) {}

export const CHAIN_NAMES_MAP = {
  [ARBITRUM]: "Arbitrum",
  [AVALANCHE]: "Avalanche",
  [FANTOM_TESTNET]: "Fantom Testnet",
  [FANTOM]: "Fantom Opera",
  [BASE]: "Base",
  [BASE_TESTNET]: "Base Testnet",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [ARBITRUM]: "0",
  [AVALANCHE]: "3000000000", // 3 gwei
  [FANTOM_TESTNET]: "3000000000", // 3 gwei
  [FANTOM]: "53000000000", // 53 gwei
  [BASE]: "0",
  [BASE_TESTNET]: "0",
};

export const MAX_GAS_PRICE_MAP = {
  [AVALANCHE]: "200000000000", // 200 gwei
  [FANTOM_TESTNET]: "200000000000", // 200 gwei
  [FANTOM]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [ARBITRUM]: 3, // 3 USD
  [AVALANCHE]: 3, // 3 USD
  [FANTOM_TESTNET]: 3, // 3 USD
  [FANTOM]: 3, // 3 USD
  [BASE]: 3, // 3 USD
  [BASE_TESTNET]: 3, // 3 USD
};

const constants = {
  [ARBITRUM]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [AVALANCHE]: {
    nativeTokenSymbol: "AVAX",
    wrappedTokenSymbol: "WAVAX",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
  },

  [FANTOM_TESTNET]: {
    nativeTokenSymbol: "FTM",
    wrappedTokenSymbol: "WFTM",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.200001"),
  },

  [FANTOM]: {
    nativeTokenSymbol: "FTM",
    wrappedTokenSymbol: "WFTM",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.200001"),
  },

  [BASE]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [BASE_TESTNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
};

const ALCHEMY_WHITELISTED_DOMAINS = ["gmx.io", "app.gmx.io"];

export const RPC_PROVIDERS = {
  [ARBITRUM]: [getDefaultArbitrumRpcUrl()],
  [AVALANCHE]: ["https://api.avax.network/ext/bc/C/rpc"],
  [FANTOM_TESTNET]: ["https://rpc.testnet.fantom.network"],
  [FANTOM]: ["https://rpc.fantom.network"],
  [BASE]: ["https://mainnet.base.org"],
  [BASE_TESTNET]: ["https://goerli.base.org"],
  // [FANTOM_TESTNET]: ["https://rpc.ankr.com/fantom_testnet/83580fcc345b95e94a59bd8fdfcef3c121a9a5af801a0e9628b5f328d426adfb"],
};

export const FALLBACK_PROVIDERS = {
  [ARBITRUM]: [getAlchemyHttpUrl()],
  [AVALANCHE]: ["https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b"],
  [FANTOM_TESTNET]: ["https://fantom-testnet.public.blastapi.io"],
  [FANTOM]: ["https://fantom-mainnet.public.blastapi.io"],
  [BASE]: ["https://1rpc.io/base"],
  [BASE_TESTNET]: ["https://1rpc.io/base-goerli"],
};

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [AVALANCHE]: {
    chainId: "0x" + AVALANCHE.toString(16),
    chainName: "Avalanche",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[AVALANCHE],
    blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
  },
  [FANTOM_TESTNET]: {
    chainId: "0x" + FANTOM_TESTNET.toString(16),
    chainName: "Fantom Testnet",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[FANTOM_TESTNET],
    blockExplorerUrls: [getExplorerUrl(FANTOM_TESTNET)],
  },
  [FANTOM]: {
    chainId: "0x" + FANTOM.toString(16),
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[FANTOM],
    blockExplorerUrls: [getExplorerUrl(FANTOM)],
  },
  [BASE]: {
    chainId: "0x" + BASE.toString(16),
    chainName: "Base",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[BASE],
    blockExplorerUrls: [getExplorerUrl(BASE)],
  },
  [BASE_TESTNET]: {
    chainId: "0x" + BASE.toString(16),
    chainName: "Base testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[BASE_TESTNET],
    blockExplorerUrls: [getExplorerUrl(BASE_TESTNET)],
  },
};

export const CHAIN_ICONS = {
  [ARBITRUM]: {
    16: "ic_arbitrum_16.svg",
    24: "ic_arbitrum_24.svg",
    96: "ic_arbitrum_96.svg",
  },
  [AVALANCHE]: {
    16: "ic_avalanche_16.svg",
    24: "ic_avalanche_24.svg",
    96: "ic_avalanche_96.svg",
  },
  [FANTOM_TESTNET]: {
    16: "ic_ftm_16.svg",
    24: "ic_ftm_24.svg",
    96: "ic_ftm_96.svg",
  },
  [FANTOM]: {
    16: "ic_ftm_16.svg",
    24: "ic_ftm_24.svg",
    96: "ic_ftm_96.svg",
  },
  [BASE]: {
    16: "ic_base_16.svg",
    24: "ic_base_24.svg",
    96: "ic_base_96.svg",
  },
  [BASE_TESTNET]: {
    16: "ic_base_16.svg",
    24: "ic_base_24.svg",
    96: "ic_base_96.svg",
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getChainIcon(chainId: number, size: 16 | 24 | 96): string | undefined {
  return CHAIN_ICONS[chainId]?.[size];
}

export function getDefaultArbitrumRpcUrl() {
  return "https://arb1.arbitrum.io/rpc";
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getExplorerUrl(chainId: number) {
  if (chainId === ARBITRUM) {
    return "https://arbiscan.io/";
  } else if (chainId === AVALANCHE) {
    return "https://snowtrace.io/";
  } else if (chainId === FANTOM_TESTNET) {
    return "https://testnet.ftmscan.com/";
  } else if (chainId === FANTOM) {
    return "https://ftmscan.com/";
  } else if (chainId === BASE) {
    return "https://basescan.org/";
  } else if (chainId === BASE_TESTNET) {
    return "https://goerli.basescan.org/";
  }
  return "https://etherscan.io/";
}

export function getHighExecutionFee(chainId: number) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId: number) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
