import { useReadLocalStorage } from "usehooks-ts";

export const MAINNET_ENABLED_KEY = "MainnetEnabled";

/**
 * Whether guarded mainnet is enabled
 *
 * @returns boolean
 */
export default function useMainnetEnabled(): boolean {
  const enabled = useReadLocalStorage<boolean | null>(MAINNET_ENABLED_KEY);

  const enabledBool = enabled !== null ? enabled : false;

  return enabledBool;
}
