import { useTimer } from "react-timer-hook";
import "./AirdropTable.css";
import InfoIcon from "../../../../img/info.svg";
import Tooltip from "components/Tooltip/Tooltip";
import { Trans, t } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";

// January 3, 2024 12:00:00 AM UTC
// const AIRDROP_1_TIMESTAMP = 1704240000 * 1000;

// Skip. VMX per user is not fixed, depends on how many people sign up
const AIRDROP_1_AMOUNT_VMX = 100;

interface AirdropTableProps {
  airdrop1: boolean | undefined;
}

export default function AirdropTable({ airdrop1 }: AirdropTableProps) {
  // const { minutes, hours, days, seconds } = useTimer({
  //   expiryTimestamp: new Date(AIRDROP_1_TIMESTAMP),
  // });
  // const timerString = `${days} D ${hours} hours ${minutes} min ${seconds} s`;

  const airdrop1Eligible = airdrop1 === undefined ? "Sign up to view" : airdrop1 === true ? "✔️ Yes" : "❌ No";

  const airdrop2Eligible = airdrop1 === undefined
    ? "Sign up to register" 
    : <ExternalLink href="https://medium.com/@voodootrade/everything-you-need-to-know-about-the-vmx-airdrop-b492523a4c0c">Read instructions</ExternalLink>

  const airdrop3Eligible = airdrop1 === undefined ? "Sign up to register" : "Registered";

  const airdrop1Message =
    airdrop1 === undefined
      ? "Register to view status"
      : airdrop1 === true
      ? "Congratulations Voodoo Chad, you qualified for airdrop 1"
      : "Unfortunately you did not qualify for airdrop 1. However you have registered for airdrop 2, good luck!";

  return (
    <div className="Component-container">
      <div className="Table-title">Airdrop schedule</div>
      <div className="Table-container">
        <table className="Airdrop-table">
          <thead>
            <tr>
              <th className="Airdrop-th">Phase</th>
              <th className="Airdrop-th">Eligibility</th>
              <th className="Airdrop-th">% Supply</th>
              <th className="Airdrop-th">$VMX allocation</th>
              <th className="Airdrop-th">Distribution date</th>
              <th className="Airdrop-th">Eligible</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="Airdrop-td">1</td>
              <td className="Airdrop-td">
                <Tooltip
                  handle={t`Voodoo chad OG Airdrop`}
                  position="right-bottom text-none"
                  renderContent={() => (
                    <div className="GlpSwap-tooltip">
                      <p className="text-white">
                        <Trans>Discord users with Voodoo Chad (OG) role</Trans>
                      </p>
                    </div>
                  )}
                />
              </td>
              <td className="Airdrop-td">0.5 %</td>
              <td className="Airdrop-td">500k</td>
              <td className="Airdrop-td">January</td>
              <td className="Airdrop-td">{airdrop1Eligible}</td>
            </tr>
            <tr>
              <td className="Airdrop-td">2</td>
              <td className="Airdrop-td">
                <Tooltip
                  handle={t`Zealy airdrop`}
                  position="right-bottom text-none"
                  renderContent={() => (
                    <div className="GlpSwap-tooltip">
                      <p className="text-white">
                        <Trans>Register for the airdrop and follow the Medium article</Trans>
                      </p>
                    </div>
                  )}
                />
              </td>
              <td className="Airdrop-td">0.5 %</td>
              <td className="Airdrop-td">500k</td>
              <td className="Airdrop-td">TBD</td>
              <td className="Airdrop-td">{airdrop2Eligible}</td>
            </tr>
            <tr>
              <td className="Airdrop-td">3</td>
              <td className="Airdrop-td">
                <Tooltip
                  handle={t`Trading Competition Airdrop`}
                  position="right-bottom text-none"
                  renderContent={() => (
                    <div className="GlpSwap-tooltip">
                      <p className="text-white">
                        <Trans>Top 200 traders in mainnet trading competition</Trans>
                      </p>
                    </div>
                  )}
                />
              </td>
              <td className="Airdrop-td">0.5 %</td>
              <td className="Airdrop-td">500k</td>
              <td className="Airdrop-td">TBD</td>
              <td className="Airdrop-td">{airdrop3Eligible}</td>
            </tr>
            <tr>
              <td className="Airdrop-td">4</td>
              <td className="Airdrop-td">Post Mainnet Airdrop</td>
              <td className="Airdrop-td">0.5 %</td>
              <td className="Airdrop-td">500k</td>
              <td className="Airdrop-td">TBD</td>
              <td className="Airdrop-td">TBD</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="Airdrop-claim-container">
        <p>{airdrop1Message}</p>
        <p className="Airdrop-timer">Airdrop 1 in January, date TBD</p>
        {airdrop1 === true && (
          <button className="StakeV2-button-solid App-card-option" disabled={true}>
            Claim Airdrop
          </button>
        )}
      </div>
    </div>
  );
}

function formatUnixTimestamp(timestampInMilliseconds: number): string {
  // Convert the timestamp to a Date object
  const date = new Date(timestampInMilliseconds);

  // Format the date
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "UTC",
  }).format(date);

  return formattedDate;
}
